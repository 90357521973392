window.addEventListener("trix-file-accept", function(event) {
  const acceptedTypes = ['image/jpeg', 'image/png']
  if (!acceptedTypes.includes(event.file.type)) {
    event.preventDefault()
    alert("Only supports attachment of jpeg or png files")
  }

  const maxFileSize = 3145728 // 3MB 
  if (event.file.size > maxFileSize) {
    event.preventDefault()
    alert("Only supports attachment of files up to 3MB")
  }
})
